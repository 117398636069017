import React from "react";
import Helmet from "react-helmet";
import Script from "components/script";
import Vimeo from "@u-wave/react-vimeo";

const NotFoundPage = () => {
  const [videoVisible, setVideoVisible] = React.useState(false);

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Newcastle Freshers Week Babylon 2020"}
        meta={[
          {
            name: `description`,
            content: "Monday 28 September 2020 @ Newcastle upon Tyne",
          },
          {
            property: `og:url`,
            content: `https://BabylonFreshersWeek.co.uk`,
          },
          {
            property: `og:title`,
            content: "Newcastle Freshers Week Babylon 2020",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: `/opengraph.jpg`,
          },
        ]}
      ></Helmet>
      <div className="header">
        <div className="loader"></div>
        <div className="video" style={{ opacity: videoVisible ? 1 : 0 }}>
          <Vimeo
            className="video-wrapper"
            video="433289450"
            autoplay
            loop
            muted
            showByline={false}
            controls={false}
            showTitle={false}
            showPortrait={false}
            background
            onPlay={() => {
              setVideoVisible(true);
            }}
            onReady={() => {
              setVideoVisible(true);
            }}
          />
        </div>
      </div>
      <div className="wrapper">
        <main>
          <div>
            <h2>Babylon Freshers Week 2020</h2>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                margin: "0 0 32px 0",
              }}
            >
              Monday 28 September 2020
            </p>
          </div>

          <div>
            <a className="button" href="https://fixr.co/event/16313137">
              BOOK TICKETS
            </a>
          </div>

          <div className="info">
            <h3>Welcome to the Notorious Party Island of BABYLON!!!</h3>
            {`We’re inviting YOU and 5,000 OTHERS to JOIN US IN EXILE for whats Guaranteed to be the WILDEST WEEK OF YOUR LIFE this Freshers 2020!! 

StageOne Events - The Curators behind your favourite events in Newcastle for over 10 years; Club Trop, Playground, SWINGERS, Feral and the notorious Student Boxing and Darts Events; Fight Club and Lets Play Darts are PROUD TO PRESENT.... 🏛️ 🏝️ BABYLON! 🏝️ 🏛️

Spread across 5 Back2Back Day of DAY & NIGHT PARTIES we’ll be taking over 5 of Newcastle's BIGGEST & HOTTEST CLUBS this Freshers 2020!!

`}

            <h3>🎪 THE PARTY </h3>
            {`A Wiseman once said... "A STRANGER IN LIFE IS A NEIGHBOUR ON THE DANCEFLOOR..." So ain't no room for politics here.... So whether your from Newcastle or Northumbria University this one is for you! We're combining the BEST BITS this fine City of Newcastle has to offer in a 5 Day SHOWCASE for everybody at both Universities to FEAST ON!

So Round up your SOUL SISTAS, DISCO DIVAS and PARTY TROOPS and jump on getting your Wristbands to this one double time or you quite literally will miss the week of your lives!! LAST YEAR OVER 5000 STUDENTS JOINED US across all of our our Freshers Week events and THIS YEAR IS LOOKING 10 x BIGGGGER!!! 

`}
            <h3>🕺🏻THE FLAVOUR </h3>
            {`Just like our boys NWA we’re bursting STRAIGHT OUTTA QUARANTINE with a Jam Packed Schedule of DISCO, HOUSE & BOOTY-BANGIN' Nights Out! So whether you’re into Dressing-up, Dressing Down, Raving or Misbehaving you best know we’ve got something for you!! + PLUS If that wasn't enough, to make sure things REALLY get off with a BANG we’ve invited the Capitals HOTTEST Record Selectors, ‘HORSE MEAT DISCO’ along for the ride, with the dating Gurus at BUMBLE App Riding Shotgun! 

So if your SOUL Searches for some SEXY Grooves with a DISCO TWIST then GET ON DOWN!!

`}
            <h3>🎟 WRISTBANDS / TICKETS </h3>
            {`Secure your wristbands NOW (and with confidence too!) - We’ve been running events in Newcastle since you lot were climbing trees and hula-hooping (11 years to be precise) so BUY WITH CONFIDENCE knowing that it ain't gonna be no Fyre Festival hype here…! - However should anything arise (ie Corona) meaning these events cannot go ahead as planned we would either reschedule or failing that issue full refunds. So rest assured knowing YOUR HARD EARNED MONEY IS SAFE! 

Your WEEK LONG PARTY BANDS start at just £5, and ENTITLE YOU TO >

> PREPAID (But Better Yet, GUARANTEED) Entry to 5 of Newcastles MOST IN DEMAND and BIGGEST Events at some of the Cities’s TOP CLUBS!

> YEAR LONG DISCOUNTS at some of the cities best bars and restaurants, including the People’s Champ and TREBLE VODKA Hotspot; "Market Shaker"

> Introductory FRESHERS PACKS, with Loads of FREE MERCH Ranging from Tees & Lanyards to MONEY OFF Coupons & STICK ON TATTOOS!?! 

SIGNUP NOW to Secure Your Guaranteed Week-Long Event Wristband!

            `}

            <h3>🍹 HYDRATION </h3>
            {`We know THIRSTY work when we see it, so you best believe we’ve worked hard to secure some of the CITIES CHEAPEST DRINKS to make sure you’re all adequately LUBRICATED long into the night. (So don't go expecting a Double to cost you an arm and a leg here, oh no, no!)


Full Individual Event Lineup to be released mid August.
Full Event Production by StageOne-Events.`}
            <p>
              Don’t forget to check us out on INSTAGRAM &gt;
              <a href="https://www.Instagram.com/StageOneEvents_Newcastle">
                www.Instagram.com/StageOneEvents_Newcastle
              </a>
            </p>
          </div>

          <div>
            <a className="button" href="https://fixr.co/event/16313137">
              BOOK TICKETS
            </a>
          </div>
        </main>
      </div>
      <footer>
        <div className="wrapper">
          <a href="https://www.instagram.com/stageoneevents_newcastle">
            Follow us on <img src="/instagram.svg" />
          </a>
          <a href="https://fixr.co" className="fixr-link">
            site by <img src="/fixr.svg" />
          </a>
        </div>
      </footer>
      <Script
        id="checkout"
        src="https://web-cdn.fixr.co/scripts/fixr-checkout-widget.v1.min.js?theme=dark"
      ></Script>
    </div>
  );
};

export default NotFoundPage;
